/* Article right section sticy scrolling style */
.wrapper {
  display: flex;
}

.wrapper aside {
  margin-top: 97px;
  padding-left: 40px;
}

aside {
  height: 52vh;
  position: sticky;
  top: 120px;
}

.rSecStyle {
  margin-top: 100px;
  margin-bottom: 100px;
}

aside img {
  width: 280px;
  height: 150px;
}

aside .lineStyle{
  border-left: 5px solid #f2c76e;
  padding-left: 10px;
}

.servSecImg{
  overflow: hidden;
  border-radius: 8px;
}